import { css, cx, keyframes } from "@emotion/css";
import type { FC } from "react";
import React, { memo } from "react";

import { colors } from "../theme/colors";

export type Props = {
  wrapperClassName?: string;
  className?: string;
  inverted?: boolean;
};

const waveContainerStyles = css`
  position: absolute;
  bottom: 0;
  left: 0px;
  display: block;
  width: 100%;
  max-height: 48px;
  margin: 0;
  z-index: 1;
`;

const waveToLeft = keyframes`
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
`;

const waveToRight = keyframes`
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
`;

const waveOneStyles = css`
  animation: ${waveToLeft} 10s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
`;

const waveTwoStyles = css`
  animation: ${waveToRight} 8s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
`;

const waveThreeStyles = css`
  animation: ${waveToLeft} 6s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
`;

const waveFourStyles = css`
  animation: ${waveToRight} 4s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }
`;

const Waves: FC<Props> = (props): JSX.Element => {
  const { className, inverted } = props;

  let colorOne = colors.white;
  let colorTwo = colors.cyan;
  let colorThree = colors.lightBlue;
  let colorFour = colors.darkBlue;

  if (inverted) {
    colorOne = colors.lightBlue;
    colorTwo = colors.cyan;
    colorThree = colors.darkBlue;
    colorFour = colors.white;
  }

  return (
    <svg
      className={cx(waveContainerStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      role="img"
      aria-label="Animating waves dividing two sections."
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      <g className={css([waveToLeft, waveOneStyles])}>
        <use xlinkHref="#gentle-wave" x="48" y="3" fill={colorFour} />
      </g>
      <g className={css([waveToRight, waveTwoStyles])}>
        <use xlinkHref="#gentle-wave" x="48" y="0" fill={colorThree} />
      </g>
      <g className={css([waveToLeft, waveThreeStyles])}>
        <use xlinkHref="#gentle-wave" x="48" y="9" fill={colorTwo} />
      </g>
      <g className={css([waveToRight, waveFourStyles])}>
        <use xlinkHref="#gentle-wave" x="48" y="6" fill={colorOne} />
      </g>
    </svg>
  );
};

Waves.displayName = "Waves";
Waves.defaultProps = {
  inverted: false,
};

export default memo(Waves);
