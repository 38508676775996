import { css, cx } from "@emotion/css";
import type { ImageProps } from "next/image";
import Image from "next/image";
import type { FC } from "react";
import React, { memo } from "react";
import { breakpoints } from "../theme/breakpoints";

import { colors } from "../theme/colors";
import { shadows } from "../theme/shadows";

export type Props = ImageProps & {
  className?: string;
  title: string;
  description: string;
};

const containerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 24px 24px;
  border-radius: 18px;
  background-color: ${colors.white};
  box-shadow: ${shadows.resting};
`;

const imageWrapperStyles = css`
  padding: calc((100% - (100% / 1.618)) / 2);
  margin: 16px 32px;
  border-radius: 50%;
  background: linear-gradient(
    to bottom,
    ${colors.darkBlue},
    ${colors.lightBlue}
  );
`;

const titleStyles = css`
  margin: 0;
  margin-top: 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: ${colors.black};

  @media (min-width: ${breakpoints.md}) {
    margin-top: 16px;
  }
`;

const descriptionStyles = css`
  margin: 0;
  margin-top: 8px;
  font-weight: 300;
  color: ${colors.darkGrey};

  @media (min-width: ${breakpoints.md}) {
    margin-top: 16px;
  }
`;

const Card: FC<Props> = (props): JSX.Element => {
  const { className, title, description, ...otherProps } = props;

  return (
    <section className={cx(containerStyles, className)}>
      <div className={imageWrapperStyles}>
        <Image alt="" {...otherProps} />
      </div>
      <h3 className={titleStyles}>{title}</h3>
      <p className={descriptionStyles}>{description}</p>
    </section>
  );
};

Card.displayName = "Card";

export default memo(Card);
