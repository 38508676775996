import { css, cx } from "@emotion/css";
import Image from "next/image";
import type { FC } from "react";
import React from "react";

import closeIcon from "../../public/icons/close.svg";

import { colors } from "../theme/colors";
import { breakpoints } from "../theme/breakpoints";
import { shadows } from "../theme/shadows";

export type Props = {
  className?: string;
  isOpen: boolean;
  onCloseClick: () => void;
};

const wrapperStyles = css`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(0);
  transform: scale(0);
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
    backdrop-filter 300ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 0ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
`;

const wrapperOpenStyles = css`
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  transform: scale(1);
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
    backdrop-filter 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const containerStyles = css`
  position: relative;
  width: calc(100% - 64px);
  margin: 32px;
  border-radius: 18px;
  background-color: ${colors.white};
  box-shadow: ${shadows.elevated};
  transform: scale(0);
  transform-origin: top right;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;

  @media (min-width: ${breakpoints.sm}) {
    max-width: 960px;
  }
`;

const containerOpenStyles = css`
  transform: scale(1);
  opacity: 1;
`;

const closeStyles = css`
  // reset
  border: none;
  padding: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:focus: {
    outline: 0;
  }

  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const contentStyles = css`
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 24px;
`;

const Modal: FC<Props> = (props): JSX.Element => {
  const { className, isOpen, onCloseClick, children } = props;

  return (
    <aside
      className={cx(wrapperStyles, {
        [wrapperOpenStyles]: isOpen,
      })}
    >
      <div
        className={cx(containerStyles, {
          [containerOpenStyles]: isOpen,
        })}
      >
        <button className={closeStyles} onClick={onCloseClick}>
          <Image src={closeIcon} alt="Close icon." width={16} height={16} />
        </button>
        <div className={cx(contentStyles, className)}>{children}</div>
      </div>
    </aside>
  );
};

Modal.displayName = "Modal";

export default Modal;
