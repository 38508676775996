import { css, cx } from "@emotion/css";
import Image from "next/image";
import type { FC } from "react";
import React, { useState } from "react";

import closeIcon from "../../public/icons/close.svg";

import { breakpoints } from "../theme/breakpoints";
import { colors } from "../theme/colors";
import { shadows } from "../theme/shadows";

export type Props = {
  className?: string;
  question: string;
  answer: string;
};

const containerStyles = css`
  position: relative;
  width: 100%;
  padding: 24px;
  border-radius: 18px;
  background-color: ${colors.white};
  box-shadow: ${shadows.resting};
  text-align: left;
  cursor: pointer;
`;

const openStyle = css`
  position: absolute;
  right: 24px;
  width: 24px;
  height: 24px;
  margin: 0;
  color: ${colors.lightBlue};
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  transform: rotate(45deg);
  transform-origin: center;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const closeStyle = css`
  transform: rotate(0);
`;

const questionStyles = css`
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: ${colors.black};
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const questionOpenStyles = css`
  color: ${colors.lightBlue};
`;

const answerStyles = css`
  max-height: 0;
  margin: 0;
  font-weight: 300;
  color: ${colors.darkGrey};
  opacity: 0;

  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1),
    margin 300ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
`;

const answerOpenStyles = css`
  max-height: 256px;
  margin-top: 8px;
  opacity: 1;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 16px;
  }
`;

const FAQItem: FC<Props> = (props): JSX.Element => {
  const { className, question, answer } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <article
      className={cx(containerStyles, className)}
      onClick={() => {
        setIsOpen((s) => !s);
      }}
    >
      <div
        className={cx(openStyle, {
          [closeStyle]: isOpen,
        })}
      >
        <Image src={closeIcon} alt="Close icon." width={14} height={14} />
      </div>
      <h3
        className={cx(questionStyles, {
          [questionOpenStyles]: isOpen,
        })}
      >
        {question}
      </h3>
      <p
        className={cx(answerStyles, {
          [answerOpenStyles]: isOpen,
        })}
      >
        {answer}
      </p>
    </article>
  );
};

FAQItem.displayName = "FAQItem";
FAQItem.defaultProps = {};

export default FAQItem;
