import { css, cx } from "@emotion/css";
import { useTranslations } from "next-intl";
import type { FC } from "react";
import React, { memo, useState, useEffect } from "react";

import { colors } from "../theme/colors";

export type Props = {
  className?: string;
};

const containerStyles = css``;

const textStyles = css`
  margin: 0;
  margin-top: 6px;
  font-weight: 300;
  color: ${colors.white};
`;

const boldStyles = css`
  font-weight: 500;
`;

const Stats: FC<Props> = (props): JSX.Element => {
  const { className } = props;
  const t = useTranslations();

  let [epochFeeAmt, setEpochFeeAmt] = useState(340);
  let [marginFeePct, setMarginFeePct] = useState(3);
  let [returnOfStakePct, setReturnOfStakePct] = useState(5);
  let [liveStakeAmt, setLiveStakeAmt] = useState(40000);

  useEffect(() => {
    // TODO: read current stats from API

    setEpochFeeAmt(340);
    setMarginFeePct(3);
    setReturnOfStakePct(5);
    setLiveStakeAmt(69081);
  }, []);

  return (
    <div className={cx(containerStyles, className)}>
      <p className={textStyles}>
        <span className={boldStyles}>{t("Stats.epochFee")}</span>{" "}
        {t("Stats.epochFeeValue", {
          value: epochFeeAmt,
        })}
      </p>
      <p className={textStyles}>
        <span className={boldStyles}>{t("Stats.marginFee")}</span>{" "}
        {t("Stats.marginFeeValue", {
          value: marginFeePct,
        })}
      </p>
      <p className={textStyles}>
        <span className={boldStyles}>{t("Stats.returnOfStake")}</span>{" "}
        {t("Stats.returnOfStakeValue", {
          value: returnOfStakePct,
        })}
      </p>
      <p className={textStyles}>
        <span className={boldStyles}>{t("Stats.liveStake")}</span>{" "}
        {t("Stats.liveStakeValue", {
          value: liveStakeAmt,
        })}
      </p>
    </div>
  );
};

Stats.displayName = "Stats";
Stats.defaultProps = {};

export default memo(Stats);
