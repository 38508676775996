import { css, cx } from "@emotion/css";
import type { FC } from "react";
import React from "react";

import { breakpoints } from "../theme/breakpoints";
import { colors } from "../theme/colors";

import Waves from "./waves";

export type Props = {
  wrapperClassName?: string;
  className?: string;
  wave?: boolean;
  invertedWave?: boolean;
};

const wrapperStyles = css`
  position: relative;
  background-color: ${colors.white};

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const wrapperWithWaveStyles = css`
  padding-bottom: 60px;
`;

const containerStyles = css`
  margin: 0 24px;

  @media (min-width: ${breakpoints.md}) {
    width: 960px;
    margin: 0 32px;
  }
`;

const Section: FC<Props> = (props): JSX.Element => {
  const { wrapperClassName, className, wave, invertedWave, children } = props;

  return (
    <div
      className={cx(
        wrapperStyles,
        {
          [wrapperWithWaveStyles]: wave || invertedWave,
        },
        wrapperClassName
      )}
    >
      <section className={cx(containerStyles, className)}>{children}</section>
      {(wave || invertedWave) && <Waves inverted={invertedWave} />}
    </div>
  );
};

Section.displayName = "Section";
Section.defaultProps = {
  wave: false,
  invertedWave: false,
};

export default Section;
