import { css, cx } from "@emotion/css";
import type { FC } from "react";
import React, { Fragment, memo } from "react";

import { breakpoints } from "../theme/breakpoints";
import { colors } from "../theme/colors";

export type Props = {
  className?: string;
  textFat: string;
  textThin: string;
  h1?: boolean;
  monotone?: boolean;
  newLine?: boolean;
};

const baseStyles = css`
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  color: ${colors.black};

  @media (min-width: ${breakpoints.md}) {
    font-size: 3em;
  }
`;

const thinStyles = css`
  font-weight: 300;
  color: ${colors.lightBlue};
`;

const monotoneStyles = css`
  color: ${colors.white};
`;

const Title: FC<Props> = (props): JSX.Element => {
  const { className, textFat, textThin, h1, monotone, newLine } = props;

  const content = (
    <Fragment>
      {textFat}
      {newLine ? <br /> : " "}
      <span
        className={cx(thinStyles, {
          [monotoneStyles]: monotone,
        })}
      >
        {textThin}
      </span>
    </Fragment>
  );

  if (h1) {
    return (
      <h1
        className={cx(
          baseStyles,
          {
            [monotoneStyles]: monotone,
          },
          className
        )}
      >
        {content}
      </h1>
    );
  }

  return (
    <h2
      className={cx(
        baseStyles,
        {
          [monotoneStyles]: monotone,
        },
        className
      )}
    >
      {content}
    </h2>
  );
};

Title.displayName = "Title";
Title.defaultProps = {
  h1: false,
  monotone: false,
  newLine: false,
};

export default memo(Title);
