import { css } from "@emotion/css";
import type { GetStaticPropsContext } from "next";
import Head from "next/head";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { Fragment, useEffect, useState } from "react";

import logoWithTextWhite from "../../public/brand/logo-with-text-white.svg";
import blockFactoryIllustration from "../../public/illustrations/block-factory.svg";
import carbonNeutralIllustration from "../../public/illustrations/carbon-neutral.svg";
import reliableIllustration from "../../public/illustrations/reliable.svg";
import secureIllustration from "../../public/illustrations/secure.svg";
import worldMapIllustration from "../../public/illustrations/world-map.svg";
import instagramIllustration from "../../public/social/instagram.svg";
import twitterIllustration from "../../public/social/twitter.svg";
import adapoolsIllustration from "../../public/social/adapools.svg";

import { throttle } from "../utils/throttle";
import { breakpoints } from "../theme/breakpoints";
import { colors } from "../theme/colors";
import { shadows } from "../theme/shadows";
import Section from "../components/section";
import Title from "../components/title";
import Stats from "../components/stats";
import Button from "../components/button";
import Link from "../components/link";
import Card from "../components/card";
import FAQItem from "../components/faq-item";
import Waves from "../components/waves";
import Modal from "../components/modal";

const headerStyles = css`
  position: sticky;
  top: 0;
  z-index: 2;

  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
    backdrop-filter 300ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const headerWithShadowStyles = css`
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  box-shadow: ${shadows.elevated};
`;

const headerContainerStyles = css`
  display: flex;
  justify-content: space-between;
  max-height: 80px;
  padding: 16px 0;
  margin: 0 24px;

  @media (min-width: ${breakpoints.md}) {
    max-height: 112px;
    width: 960px;
    padding: 32px 0;
    margin: 0 32px;
  }
`;

const heroWrapperStyles = css`
  padding-bottom: 60px;
  background: linear-gradient(
    to right,
    ${colors.darkBlue},
    ${colors.lightBlue}
  );
`;

const heroStyles = css`
  display: grid;
  grid-template-columns: 1fr;
  padding: 32px 0;

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1.618fr 1fr;
    padding: 48px 0;
  }
`;

const heroContentStyles = css`
  @media (min-width: ${breakpoints.md}) {
    margin-right: 48px;
  }
`;

const heroDescriptionStyles = css`
  margin: 0;
  margin-top: 24px;
  font-weight: 300;
  color: ${colors.white};

  @media (min-width: ${breakpoints.md}) {
    margin-top: 32px;
  }
`;

const heroStatsStyles = css`
  margin-top: 32px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 48px;
  }
`;

const heroButtonStyles = css`
  margin-top: 32px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 48px;
  }
`;

const blockFactoryStyles = css`
  margin-top: 48px;
`;

const offeringStyles = css`
  padding: 48px 0;
  text-align: center;
`;

const offeringCardsStyles = css`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 24px;
  margin-top: 24px;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    margin-top: 32px;
  }
`;

const missionWrapperStyles = css`
  background: linear-gradient(to top, ${colors.darkBlue}, ${colors.lightBlue});
`;

const missionStyles = css`
  padding: 48px 0;
  text-align: center;
`;

const missionContentStyles = css`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.md}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1.618fr;
    align-items: center;
  }
`;

const missionTextStyles = css`
  font-weight: 300;
  text-align: center;
  color: ${colors.white};

  @media (min-width: ${breakpoints.md}) {
    margin-right: 48px;
    text-align: left;
  }
`;

const missionDescriptionStyles = css`
  margin: 0;
  margin-top: 24px;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: inherit;
  }
`;

const worldMapStyles = css`
  margin-top: 32px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`;

const faqWrapperStyles = css`
  padding-bottom: 60px;
`;

const faqStyles = css`
  padding: 48px 0;
  text-align: center;
`;

const faqItemStyles = css`
  margin-top: 24px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 32px;
  }
`;

const footerStyles = css`
  position: relative;
  background: linear-gradient(to top, ${colors.darkBlue}, ${colors.lightBlue});

  @media (min-width: ${breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const footerContainerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  margin: 0 24px;

  @media (min-width: ${breakpoints.md}) {
    width: 960px;
    padding: 32px 0;
    margin: 0 32px;
  }
`;

const footerSocialStyles = css`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 48px;
  }
`;

const footerSocialIconStyles = css`
  margin: 0 16px;
`;

const footerAddressStyles = css`
  margin: 0;
  margin-top: 16px;
  font-weight: 300;
  text-align: center;
  color: ${colors.white};

  @media (min-width: ${breakpoints.md}) {
    margin-top: 24px;
  }
`;

const footerDividerStyles = css`
  margin: 16px 0;
  border: none;
  border-top: 1px solid ${colors.lightBlue};
  border-radius: 0.5px;

  @media (min-width: ${breakpoints.md}) {
    margin: 24px 0;
  }
`;

const footerCopyrightStyles = css`
  margin: 0;
  font-weight: 300;
  text-align: center;
  color: ${colors.white};

  // @media (min-width: ${breakpoints.md}) {
  //   text-align: left;
  // }
`;

const modalStyles = css`
  // text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
  }
`;

export default function Home() {
  const t = useTranslations();

  useEffect(() => {
    const handleScrollChange = () => {
      const currentScrolledPosition = window.scrollY || window.pageYOffset;

      const maybeHeader = document.querySelector(`.${headerStyles}`);

      if (!maybeHeader) {
        return;
      }

      if (currentScrolledPosition > 0) {
        maybeHeader.classList.add(headerWithShadowStyles);
      } else {
        maybeHeader.classList.remove(headerWithShadowStyles);
      }
    };

    const onScrollChange = () => {
      throttle(handleScrollChange, 250);
    };

    window.addEventListener("scroll", onScrollChange);

    return () => window.removeEventListener("scroll", onScrollChange);
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModalClick = () => setModalIsOpen((s) => !s);

  return (
    <Fragment>
      <Head>
        <title>CryptoPool.dev</title>
        <meta
          name="description"
          content="Secure and reliable Cardano pool located in Amsterdam, operating with a carbon-neutral footprint. 🌍🌿"
        />
      </Head>

      <header className={headerStyles}>
        <div className={headerContainerStyles}>
          <Image
            src={logoWithTextWhite}
            alt="The CryptoPool.dev logo."
            width={168}
            height={48}
          />
          <nav>
            <Button text={t("Hero.cta") as string} onClick={toggleModalClick} />
          </nav>
        </div>
      </header>

      <main>
        <Section
          wrapperClassName={heroWrapperStyles}
          className={heroStyles}
          wave
        >
          <div className={heroContentStyles}>
            <Title
              textFat={t("Hero.titleLineOne") as string}
              textThin={t("Hero.titleLineTwo") as string}
              h1
              monotone
              newLine
            />
            <p className={heroDescriptionStyles}>{t("Hero.description")}</p>
            <Stats className={heroStatsStyles} />
            <Button
              className={heroButtonStyles}
              text={t("Hero.cta") as string}
              onClick={toggleModalClick}
            />
          </div>
          <div className={blockFactoryStyles}>
            <Image
              src={blockFactoryIllustration}
              alt="Block factory illustration."
            />
          </div>
        </Section>

        <Section className={offeringStyles} invertedWave>
          <Title
            textFat={t("Offering.titleLineOne") as string}
            textThin={t("Offering.titleLineTwo") as string}
          />
          <div className={offeringCardsStyles}>
            <Card
              src={carbonNeutralIllustration}
              alt={t("Offering.itemOneAlt") as string}
              title={t("Offering.itemOneTitle") as string}
              description={t("Offering.itemOneDescription") as string}
            />
            <Card
              src={reliableIllustration}
              alt={t("Offering.itemTwoAlt") as string}
              title={t("Offering.itemTwoTitle") as string}
              description={t("Offering.itemTwoDescription") as string}
            />
            <Card
              src={secureIllustration}
              alt={t("Offering.itemThreeAlt") as string}
              title={t("Offering.itemThreeTitle") as string}
              description={t("Offering.itemThreeDescription") as string}
            />
          </div>
        </Section>

        <Section
          wrapperClassName={missionWrapperStyles}
          className={missionStyles}
          wave
        >
          <Title
            textFat={t("Mission.titleLineOne") as string}
            textThin={t("Mission.titleLineTwo") as string}
            monotone
          />
          <div className={missionContentStyles}>
            <div className={missionTextStyles}>
              <p className={missionDescriptionStyles}>
                {t("Mission.description") as string}
              </p>
              <Link
                className={heroButtonStyles}
                text={t("Mission.cta") as string}
                href="https://roadmap.cardano.org/"
              />
            </div>
            <div className={worldMapStyles}>
              <Image src={worldMapIllustration} alt="World map illustration." />
            </div>
          </div>
        </Section>

        <Section wrapperClassName={faqWrapperStyles} className={faqStyles}>
          <Title
            textFat={t("FAQ.titleLineOne") as string}
            textThin={t("FAQ.titleLineTwo") as string}
          />
          <FAQItem
            className={faqItemStyles}
            question="What is Cardano?"
            answer="Cardano is a decentralized public blockchain and cryptocurrency project and is fully open source. It combines pioneering technologies to provide unparalleled security and sustainability to decentralized applications, systems, and societies."
          />
          <FAQItem
            className={faqItemStyles}
            question="What is a Stake Pool?"
            answer="A stake pool is a reliable server node that focuses on maintenance and holds the combined stake of various stakeholders in a single entity. Stake pools are responsible for processing transactions and producing new blocks and are at the core of Ouroboros, the Cardano proof-of-stake protocol."
          />
          <FAQItem
            className={faqItemStyles}
            question="How does it benefit me?"
            answer="By staking you're delegating your ADA to a Stake Pool you directly participate in the network and help increasing the robustness and decentralization of Cardano. For this you can receive a monetary reward in ADA."
          />
          <FAQItem
            className={faqItemStyles}
            question="How do I delegate ADA to a stake pool?"
            answer="To delegate ADA, you first buy ADA on an exchange, open up a wallet application (e.g. Daedalus, Yoroi or Adalite) create a wallet, send your ADA to that wallets' address and select a Stake Pool (preferably SBLYR) in the Staking menu directly in the wallet application. Each wallet application looks a bit different but overall it's very straight forward."
          />

          <Waves inverted />
        </Section>
      </main>

      <footer className={footerStyles}>
        <div className={footerContainerStyles}>
          <Image
            src={logoWithTextWhite}
            alt="The CryptoPool.dev logo."
            width={252}
            height={72}
          />
          <div className={footerSocialStyles}>
            <a
              className={footerSocialIconStyles}
              href="https://instagram.com/cryptopool.dev?utm_medium=copy_link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={instagramIllustration}
                alt="The instagram logo."
                width={32}
                height={32}
              />
            </a>
            <a
              className={footerSocialIconStyles}
              href="https://twitter.com/cryptopoold"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={twitterIllustration}
                alt="The twitter logo."
                width={32}
                height={32}
              />
            </a>
            <a
              className={footerSocialIconStyles}
              href="https://adapools.org/pool/a343a6e2eb4bb7f8abf039b82e579131b56966d88d9937e679365269"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={adapoolsIllustration}
                alt="The adappools logo."
                width={32}
                height={32}
              />
            </a>
          </div>
          <address className={footerAddressStyles}>
            Amsterdam, The Netherlands
          </address>
          <hr className={footerDividerStyles} />
          <p className={footerCopyrightStyles}>
            Copyright &copy; {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
      </footer>
      <Modal
        className={modalStyles}
        isOpen={modalIsOpen}
        onCloseClick={toggleModalClick}
      >
        <Title textFat="How To" textThin="Delegate" />
        <p>Step 1: Buy Cardano coins (ADA).</p>
        <p>
          Step 2: Transfer your ADA to one of these official wallets: Daedalus,
          Yoroi or AdaLite.
        </p>
        <p>
          Step 3: Go to the staking/delegation section and look for: CRYPO.
          (a343a6e2eb4bb7f8abf039b82e579131b56966d88d9937e679365269)
        </p>
        <p>
          Step 4: Hit delegate and choose the wallet you want to use for
          staking.
        </p>
      </Modal>
    </Fragment>
  );
}

export function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: require(`../i18n/index/${locale}.json`),
    },
  };
}
