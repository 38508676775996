import { css, cx } from "@emotion/css";
import type { FC } from "react";
import React, { memo } from "react";

import { colors } from "../theme/colors";
import { shadows } from "../theme/shadows";

export type Props = {
  className?: string;
  text: string;
  href: string;
  glass?: boolean;
};

const containerStyles = css`
  display: inline-block;

  min-width: 128px;
  padding: 14px 24px;
  border-radius: 14px;
  background-color: ${colors.white};
  box-shadow: ${shadows.resting};
  font: inherit;
  font-weight: 500;
  line-height: 20px;
  color: ${colors.darkBlue};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    cursor: pointer;
    box-shadow: ${shadows.elevated};
    transform: translateY(-2px);
  }
`;

const glassStyles = css`
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
`;

const Link: FC<Props> = (props): JSX.Element => {
  const { className, text, href, glass } = props;

  return (
    <a
      className={cx(
        containerStyles,
        {
          [glassStyles]: glass,
        },
        className
      )}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );
};

Link.displayName = "Link";
Link.defaultProps = {
  glass: false,
};

export default memo(Link);
